import React from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const ProductCardWrapper = styled(Link)`
  display: flex;
  border: 1px solid ${(props) => props?.$borderColor || "var(--grey-light)"};
  border-radius: var(--border-regular);
  width: 100%;
  color: var(--black);
  padding-bottom: 0.4rem;
  padding-left: 0.4rem;
  padding-right: 1.6rem;
  padding-top: 0.4rem;
  background: var(--white);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0);
  transition: transform 0.4s ease, box-shadow 0.4s ease, border-color 0.4s ease;
  max-height: ${(props) => (props?.$smallVersion ? "15rem" : "")};
  position: relative;

  @media (min-width: 768px) {
    &:hover {
      transform: scale(1.05);
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.125);
      border-color: var(--black);
    }
  }

  @media (min-width: 1024px) {
    z-index: 3;
  }
`;

const ProductCardImageWrapper = styled.div`
  width: 14rem;
  height: ${(props) => (props?.$smallVersion ? "14rem" : "14rem")};
  background: var(--grey-dark);
  display: flex;
  border-top-left-radius: var(--border-small);
  border-bottom-left-radius: var(--border-small);
`;

const ProductCardTextWrapper = styled.div`
  flex: 1;
  padding-left: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ProductCardTextTitle = styled.div`
  span {
    font-family: var(--font-oswald-bold);
    color: var(--red-dark);
    text-transform: uppercase;
    font-size: 1rem;

    @media (min-width: 768px) {
      font-size: 1.2rem;
    }
  }

  h5 {
    font-size: ${(props) => (props?.$smallVersion ? "1.2rem" : "1.6rem")};
    border-bottom: 2px solid var(--grey-dark);
    padding-bottom: 1rem;
    margin-bottom: 0.8rem;
    height: ${(props) => (props?.$smallVersion ? "4.2rem" : "6.8rem")};
    overflow: hidden;
    text-align: left;
    letter-spacing: 0px;
    text-transform: uppercase;

    @media (min-width: 768px) {
      font-size: ${(props) => (props?.$smallVersion ? "1.2rem" : "1.6rem")};
    }
  }

  p {
    margin: 0;
    font-weight: 800;
    font-family: var(--font-chinese);
    font-size: ${(props) => (props?.$smallVersion ? "1.2rem" : "1.4rem")};
  }
`;

const ProductCardText = styled.div`
  padding-bottom: 0.8rem;
  p {
    font-size: ${(props) => (props?.$smallVersion ? "1rem" : "1.2rem")};
    font-family: var(--font-oswald-regular);
    text-transform: uppercase;
    margin-bottom: 0px;
    margin-top: 0.4rem;
  }
`;

export default function ProductCard({
  itemImage,
  itemLink,
  itemBrand,
  itemTitle,
  itemChinese,
  itemCode,
  itemInfo,
  borderColor,
  smallVersion,
}) {
  return (
    <ProductCardWrapper
      to={`/product/${itemLink}`}
      $borderColor={borderColor}
      $smallVersion={smallVersion}
    >
      <ProductCardImageWrapper $smallVersion={smallVersion}>
        <GatsbyImage image={itemImage} alt={itemTitle} />
      </ProductCardImageWrapper>
      <ProductCardTextWrapper>
        <ProductCardTextTitle $smallVersion={smallVersion}>
          {!smallVersion && <span>{itemBrand}</span>}
          <h5>{itemTitle}</h5>
          <p>{itemChinese}</p>
        </ProductCardTextTitle>
        <ProductCardText $smallVersion={smallVersion}>
          <p>
            <strong>item code:</strong> {itemCode}
          </p>
          <p>
            <strong>info:</strong> {itemInfo}
          </p>
        </ProductCardText>
      </ProductCardTextWrapper>
    </ProductCardWrapper>
  );
}
