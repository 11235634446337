import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import OgImage from "../assets/images/shueyshing_og_image.jpg";

export default function Seo({ children, location, description, title, image }) {
  const { site } = useStaticQuery(graphql`
    query {
      site: sanitySiteSettings {
        title
        description
      }
    }
  `);
  return (
    <Helmet titleTemplate={`%s - ${site.title}`}>
      <html lang='en' />
      <title>{title}</title>
      <link
        rel='apple-touch-icon'
        sizes='180x180'
        href='/apple-touch-icon.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='32x32'
        href='/favicon-32x32.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='16x16'
        href='/favicon-16x16.png'
      />
      <link rel='manifest' href='/site.webmanifest' />
      <meta name='viewport' content='width=device-width, initial-scale=1.0' />
      <meta charSet='utf-8' />
      <meta name='description' content={site.description} />
      {location && <meta property='og:url' content={location.href} />}
      <meta
        property='og:image'
        content={image || `https://www.shueyshing.com.au${OgImage}`}
      />
      <meta property='og:title' content={title} key='ogtitle' />
      <meta property='og:site_name' content={site.title} key='ogsitename' />
      <meta property='og:description' content={description} key='ogdesc' />
      <meta name='twitter:card' content={description} />
      {children}
    </Helmet>
  );
}
